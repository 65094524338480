import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mkubqr9', 'template_4yxqc3m', e.target, 'ZRnaxOFhnzpDdbvbK')
      .then((result) => {
        console.log(result.text);
        setIsSubmitted(true);
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset();
  };

  return (
    <div>
      <div className="container my-5">
        <h1 className="display-4 text-center fw-bold jackie-green my-5">We'd Love to Hear from You!</h1>
        <p className="lead text-center">Whether you have questions, feedback, or want to learn more about Jackie Amrikhas' campaign, feel free to reach out. We're here to listen and provide any information you need to get involved and make a difference in Sausalito.</p>

        <div className="row">
          <div className="col-md-6">
            <h2 className="fw-bold mb-4 jackie-blue">Contact Us</h2>

            {isSubmitted ? (
              <div className="alert alert-success" role="alert">
                Thank you for your message! We will get back to you soon.
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="John Doe"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="johndoe@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="(123) 456-7890"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="5"
                    placeholder="Your message here..."
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            )}
          </div>

          <div className="col-md-6">
            <h2 className="fw-bold mb-4 jackie-blue">Our Address</h2>
            <address>
              <p className="mb-1"><strong>Jackie Amrikhas Campaign Office</strong></p>
              <p className="mb-1">180 Harbor Drive Suite 204B</p>
              <p className="mb-1">Sausalito, CA 94965</p>
              <p className="mb-1"><strong>Phone:</strong> 415-331-2137</p>
              <p className="mb-1"><strong>Email:</strong> <a href="mailto:jackieforsausalito@gmail.com">jackieforsausalito@gmail.com</a></p>
            </address>
            <h2 className="fw-bold mt-4 jackie-blue">Find Us Here</h2>
            <div className="map-container">
              <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.691010509987!2d-122.49892009999999!3d37.867519699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085850415c202b5%3A0xbc30ce84116b6add!2s180%20Harbor%20Dr%20%23204b%2C%20Sausalito%2C%20CA%2094965!5e0!3m2!1sen!2sus!4v1724392695658!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
