import React from 'react';

const AboutUs = () => {
  return (
    <div>
    <div className="container my-5">
      <h1 className="display-4 fw-bold text-center my-5 jackie-green">About Jackie Amrikhas</h1>

      <section className="mb-5">
        <h2 className='jackie-blue'>A Resident and Advocate for Sausalito</h2>
        <p>
          Jackie Amrikhas has called Sausalito home for 21 years and has been a proud resident of the Bay Area for 50 years. Her deep roots in the community have fueled her passion for serving and advocating for her neighbors.
        </p>
      </section>

      <section className="mb-5">
        <h2 className='jackie-blue'>A Career Built on Expertise and Integrity</h2>
        <p>
          With over three decades of experience as a Certified Public Accountant, Jackie’s career has been marked by a commitment to financial integrity and public service. She spent five years as an IRS Agent, gaining invaluable insights into the complexities of the tax system. Jackie went on to serve as a Tax Manager at a prominent Bay Area firm for another five years before establishing her private CPA practice in Sausalito in 2008.
        </p>
      </section>

      <section className="mb-5">
        <h3 className='jackie-blue'>Education and Professional Credentials</h3>
        <ul>
          <li><strong>Bachelor of Science in Business Administration (Accounting)</strong> - San Jose State University</li>
          <li><strong>Master of Law in Taxation & Juris Doctor</strong> - Golden Gate University, San Francisco</li>
        </ul>
      </section>

      <section className="mb-5">
        <h2 className='jackie-blue'>A Dedication to Community Service</h2>
        <p>
          Jackie’s dedication to her community extends beyond her professional life. She has volunteered her expertise in various pro bono initiatives, including a tax clinic and an elder law clinic. Her service also includes working with Legal Aid in Marin County and serving as Treasurer for the Chamber of Commerce in Petaluma.
        </p>
        <ul>
          <li><strong>Pro Bono Tax Clinic</strong></li>
          <li><strong>Pro Bono Elder Law Clinic (2003)</strong></li>
          <li><strong>Legal Aid Marin County (2005)</strong></li>
          <li><strong>Treasurer, Chamber of Commerce, City of Petaluma (1993)</strong></li>
          <li><strong>Advisory Board Member, Business Tax, City of Petaluma (1994)</strong></li>
        </ul>
      </section>
    </div>
    </div>
  );
};

export default AboutUs;
