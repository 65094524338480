import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EndorsementsPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    organization: '',
    title: '',
    quote: '',
  });

  const [submitted, setSubmitted] = useState(false); // State to manage form submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS configuration
    const serviceID = 'service_mkubqr9';
    const templateID = 'template_vo2i8d8';
    const userID = 'ZRnaxOFhnzpDdbvbK';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true); // Set submitted to true on success
      })
      .catch((error) => {
        console.error('FAILED...', error);
      });
  };

  const endorsements = [
    {
      name: 'John Whales',
      description: 'Certified Public Accountant, 51 years. Treasurer, Sausalito Yacht Club (2016-2023). Sausalito Resident, 51 years.',
      quote: 'I recommend Jackie to serve on the Sausalito City Council because she is a competent CPA and understands finances.'
    },
    {
      name: 'Alice Merrill',
      description: 'Sausalito Resident, 60 years, 2nd generation.'
    },
    {
      name: 'Linda Pfeifer',
      description: 'Former Sausalito City Councilmember, Current Secretary Open Space Sausalito',
      quote: 'I endorse Jacqueline Amrikhas for Sausalito City Council. She has the leadership qualities, straight talk, and financial acumen our City Council sorely needs right now.'
    },
    {
      name: 'Wayne Bonnett',
      description: 'Former Sausalito City Council member, former Marin County Planning Commissioner, Historian, Open Space Sausalito, Author and Artist, 58 Year Resident.'
    },
    {
      name: 'Kay Mitzel',
      quote: 'I support Jackie who will work to bring back financial stability to our town and not approve projects from special interests. I have lived here 45+ years and want the council to be transparent and not cater to special interests. As a retired professional and recent Chair, Executive Board of Sausalito Yacht Club, I understand the importance of managing budgets. Vote for Jackie!'
    },
    {
      name: 'Theresa O\'Connor',
      quote: 'Jackie served as the Treasurer for The Petaluma of chamber of commerce. During her term as Treasurer, Jackie balanced the budget from the brink of bankruptcy.'
    },
    {
      name: 'Bob Lalanne',
      description: ''
    },
    {
      name: 'Mary Goff',
      description: ''
    },
    {
      name: 'Bob Mitchell',
      description: 'Sausalito City Council 1988 to 1996. Mayor of City of Sausalito April 1989 to March 1990.'
    },
    {
      name: 'Jill Hoffman',
      description: 'Current Sausalito City Council Member. Former Sausalito Mayor',
      quote: 'Candidate Jacqueline Amrikhas, CPA has my full endorsement for your vote for Sausalito City Council...We must have councilmembers who are financially literate and responsible.',
      image: '/images/image.png'
    },
    {
      name: 'Jim Richard',
      description: 'Director Open Space for Sausalito. Sausalito Resident for 12 years.'
    },
    {
      name: 'William Monnet',
      description: 'Open Space Sausalito',
      quote: 'I met with Jackie to understand her analysis of Sausalito\'s present challenges and her vision for Sausalito\'s future. I was much impressed with her financial literacy, her vision for a Sausalito consistent with the wonderful town that it is today, and her commitment to keeping the promises made to citizens present and past. Sausalito needs Jackie.'
    },
    {
      name: 'Dan Rheiner',
      quote: 'I am proud to endorse Jacqueline Amrikhas for the Sausalito City Council. I understand the importance of strong fiscal leadership, from my own substantial experience in government accounting as a Director of the Sausalito Marin City Sanitary District since 2011, and as SVP and Regional Manager for Bank of Marin and Union Bank. I have strong ties with our Sausalito community as the former President of the Sausalito Rotary Club and past Chair of the Sausalito Yacht Club Executive Committee.'
    }
  ];

  return (
    <div className="container my-5">
      <div className="text-center mb-4">
        <h1 className="display-4 fw-bold jackie-green">Endorse Jackie</h1>
        <p className="lead">
          By submitting this form, you give your permission for us to list your endorsement on our website,
          social media site, and other publicity channels.
        </p>
      </div>

      {/* Current Endorsements */}
      <div className="row mb-5">
        <div className="col-md-6">
          <h3 className="fw-bold jackie-blue">Current Endorsements</h3>
          <p className='lead fw-bold my-5 me-4'>Note that organizational affiliations are shown for identification purposes only, and do not imply
            an endorsement from the entire organization unless clearly stated.</p>

            {endorsements.map((endorsement, index) => (
        <div key={index} className="mb-4 p-4 border rounded shadow-sm bg-light">
          <h4 className="fw-bold">{endorsement.name}</h4>
          <p>{endorsement.description}</p>
          {endorsement.quote && (
            <blockquote className="blockquote">
              <p className="mb-0">{endorsement.quote}</p>
            </blockquote>
          )}
          {endorsement.image && (
            <img
              src={endorsement.image}
              alt={endorsement.name}
              className="img-fluid mt-3 border border-dark-subtle rounded-1"
            />
          )}
        </div>
      ))}

        </div>



        {/* Endorsement Form */}
        <div className="col-md-6">
          <h3 className="fw-bold jackie-blue">Submit Your Endorsement</h3>
          {submitted ? (
            <div className="alert alert-success" role="alert">
              Thank you for your endorsement! We appreciate your support.
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="organization" className="form-label">Organization (if applicable)</label>
                <input
                  type="text"
                  className="form-control"
                  id="organization"
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">Title (if applicable)</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="quote" className="form-label">Endorsement Quote</label>
                <textarea
                  className="form-control"
                  id="quote"
                  name="quote"
                  rows="3"
                  value={formData.quote}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary">Submit Endorsement</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EndorsementsPage;
